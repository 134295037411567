<template>
  <div class="feiyongBlock" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="feiyongxiangxi blockOpt" :style="!openControlone ? 'height:80px' : ''" style="margin-right: 40px">
      <h1>
        <span style="font-size: 16px;">{{ $fanyi('费用明细') }}</span>
      </h1>
      <div class="">
        <div class="feiYongMingXi">
          <div class="feiYongMingXiDetail">

            <p>
              <label>{{ $fanyi('国际物流费用') }}：</label><span>$ {{ $fun.EURNumSegmentation(datas.logistics_price) }}</span>
            </p>
            <p>
              <label>{{ $fanyi('美国物流费用') }}：</label><span>$ {{ $fun.EURNumSegmentation(datas.foreign_express_price) }}
              </span>
            </p>
            <p>
              <label>{{ $fanyi('其他费用') }}：</label><span>$ {{ $fun.EURNumSegmentation(datas.other_price) }}</span>
            </p>

          </div>
          <!--形式发票暂时隐藏  -->
          <!-- <div class="xiazai" v-if="['待付款', '等待付款', '待发货', '美国国内配送中', '已付款待确认', '已发货', '美国国内配送中'].indexOf(
            this.$parent.$parent.pageStatus
          ) != -1">
            <el-button type="primary" @click="getFapiao">{{
              $fanyi('形式发票')
            }}</el-button>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Dialog from '../../../../../../../components/public/Dialog.vue'
import Europess from '../../../../../../../api/wangZhi'
let Europe = Europess + '/client/'
import axios from 'axios'
import { DOWLOAD_FILE } from '@/utlis/dowload'
export default {
  name: 'kk',
  data() {
    return {
      fullscreenLoading: false,
      showAddressList: false,
      openControlone: true,
      openControltwo: true,
      openControlthree: true,
      fullscreenLoading: false,
      config: {
        top: '20vh',
        width: '1000px',
        title: this.$fanyi('收货地址详情'),
        btnTxt: [this.$fanyi('关闭')],
      },
    }
  },
  components: { Dialog },
  computed: {
    datas() {

      return this.$parent.$parent.datas.result
    },
    userActiveAddaderss() {

      return this.$parent.datas.result.receive
    },
    // userActiveAdd() {

    //   return this.$parent.userActiveAdd
    // },
    // userActiveAddName() {
    //   return this.$parent.userActiveAddName
    // },
    // form() {
    //   return this.$parent.form
    // },
    // useraddLists() {
    //   return this.$parent.useraddLists
    // },
  },
  created() { },
  methods: {
    getFapiao() {
      this.fullscreenLoading = true
      let params = {
        porder_sn: this.$parent.$parent.datas.porder_sn,
      }
      this.fullscreenLoading = true
      axios
        .post(Europe + 'download.invoiceDownload', params, {
          responseType: 'blob', // 1.首先设置responseType对象格式为 blob:
        })
        .then((res) => {

          this.fullscreenLoading = false

          if (res.size < 200) {
            return this.$message.warning(this.$fanyi('操作失败'))
          }


          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement('a')
          a.href = url
          a.download = 'Proforma invoice' + this.$parent.$parent.datas.porder_sn + '.xls'
          a.click()
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url)
          this.diaShow = !this.diaShow
        })
        .catch((error) => {
          this.$message(error.message)
        })
    },
    downloadFile(url, name = 'imagen') {
      var a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', name)
      let clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../../css/mixin.scss';

.feiyongBlock {
  display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  margin-bottom: 40px;

  .blockOpt {
    width: 320px;
    background-color: white;
    overflow: hidden;

    height: 320px;

    h1 {
      height: 80px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 600;
        color: #222222;
        line-height: 26px;
      }

      .roteteIcon {
        transform: rotate(180deg);
      }
    }

    &.feiyongxiangxi {
      .feiYongMingXiDetail {
        margin-top: -20px;

        p {
          line-height: 20px;
          padding: 19px 0;
          border-bottom: solid 1px #e2e2e2;
          display: flex;
          justify-content: space-between;
          align-items: center;

          label {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }

          span {
            font-size: 14px;
            font-weight: 500;
            color: #222222;
            line-height: 20px;
          }
        }
      }

      .xiazai {
        margin-top: 30px;
        text-align: left;

        button {
          width: 237px;
          height: 44px;
          background: #fff2e8;
          border-radius: 4px;
          border: 1px solid #ff730b;

          color: #ff730b;

          border: 1px solid #ff730b;
        }
      }

      .jinkoushanginfo {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;

        .jinkoushanginfoLabel {
          width: 80px;
          text-align: right;
        }

        //.
        //class="jinkoushanginfoLabel">{{ $fanyi("收货人") }}：</div><div class="jinkoushanginfoValue"
      }
    }
  }
}
</style>
