import { render, staticRenderFns } from "./obligationOrderAddress.vue?vue&type=template&id=05b33404&scoped=true&"
import script from "./obligationOrderAddress.vue?vue&type=script&lang=js&"
export * from "./obligationOrderAddress.vue?vue&type=script&lang=js&"
import style0 from "./obligationOrderAddress.vue?vue&type=style&index=0&id=05b33404&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b33404",
  null
  
)

export default component.exports