<template>
  <div class="DeliveryDetails">
    <HomePageTop />
    <Dialog ref="dialog" :config="config" />

    >

    <div class="showdow">
      <!-- 表格 -->
      <div id="DeliveryDetailsTable">
        <div class="DeliveryDetailsTitle">

          <div class="left">{{ $fanyi('配送单号') }}：{{ porder_sn }}</div>
          <div class="right"> <span class="tishi" v-if="['待付款', '报价中', '等待付款', '美国国内配送中'].indexOf(
            pageStatus
          ) != -1
          ">
              {{ $fanyi('提示：如果您想修改装箱单，请联系我们。') }}
            </span>

            <!-- 下载 -->
            <button class="Downloadpackinglist" @click="Downloadpackinglist">
              <img src="@/assets/newImg/Packinglist/download.svg" alt="">
              {{ $fanyi('下载包装清单') }}
            </button>
          </div>
        </div>
        <!-- 如果是付款后就展示付款后页面可进行切换 -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$fanyi('配送单详情')" name="porder_sn">
            <div class="main">
              <div class="head">
                <div class="check-Box mainTd"></div>

                <div class="choice mainTd">
                  <!-- <div :title="$fanyi('选择')">{{ $fanyi("选择") }}</div> -->
                </div>
                <div class="goods mainTd">
                  <div :title="$fanyi('产品')">{{ $fanyi('产品') }}</div>
                </div>
                <div class="detail mainTd">
                  <div :title="$fanyi('属性')">{{ $fanyi('属性') }}</div>
                </div>
                <div class="price mainTd">
                  <div :title="$fanyi('单价') + '($)'">{{ $fanyi('单价') }}($)</div>
                </div>
                <div class="goodsNum mainTd">
                  <div :title="$fanyi('数量')">{{ $fanyi('数量') }}</div>
                </div>
                <div class="totalPrice mainTd">
                  <div :title="$fanyi('总价') + '($)'">{{ $fanyi('总价') }}($)</div>
                </div>
                <div class="homeFreight mainTd">
                  <div :title="$fanyi('国内运费') + '($)'">
                    {{ $fanyi('国内运费') }}($)
                  </div>
                </div>
                <!--            <div-->
                <!--              class="tariff mainTd"-->
                <!--              v-if="['临时保存', '报价中',''].indexOf(pageStatus) == -1"-->
                <!--            >-->
                <!--              <div :title="$fanyi('关税') + '($)'">-->
                <!--                {{ $fanyi("关税") }}($)-->
                <!--              </div>-->
                <!--            </div>-->
                <div class="deliverableQuantity mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1">
                  <div :title="$fanyi('可发货数量')">
                    {{ $fanyi('可发货数量') }}
                  </div>
                </div>
                <div class="putForwardNumber mainTd">
                  <div :title="$fanyi('提货数量')">{{ $fanyi('提货数量') }}</div>
                </div>
                <div class="remark mainTd">
                  <div :title="$fanyi('备注')">{{ $fanyi('备注') }}</div>
                </div>
              </div>
              <div class="body">
                <div class="bodyOpt" v-for="(orderItem, orderIndex) in tableData" :key="orderIndex">
                  <div class="optTitle">


                    <div class="orderSn">
                      <img :class="orderItem.showGoodsList ? 'isShow' : ''" @click="
                        orderItem.showGoodsList = !orderItem.showGoodsList
                      $forceUpdate()
                        " :src="orderItem.showGoodsList
                          ? require('../../../../../../assets/warehouse_1.svg')
                          : require('../../../../../../assets/warehouse_0.svg')
                          " alt="" />
                      <span>{{ $fanyi('订单号') }}：</span>
                      <button @click="
                        $fun.toPage(
                          '/orderDetails?type=' +
                          'peisong' +
                          '&order_sn=' +
                          orderItem.order_sn
                        )
                        ">
                        {{ orderItem.order_sn }}
                      </button>
                    </div>
                  </div>
                  <div class="optBody" v-if="orderItem.showGoodsList">
                    <div class="bodyRow" v-for="(goodsItem, goodsIndex) in orderItem.order_detail" :key="goodsIndex">
                      <!-- 选择-番号 -->
                      <div class="check-Box mainTd">
                        {{ goodsIndex + 1 }}
                      </div>

                      <!-- 产品 -->
                      <div class="goods mainTd">
                        <el-popover placement="right" trigger="hover">
                          <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                          <el-image slot="reference" @click="
                            $fun.toCommodityDetails({
                              goods_id: goodsItem.goods_id,
                              shop_type: goodsItem.shop_type,
                            })
                            " :src="goodsItem.pic">
                            <div slot="error" :src="goodsItem.pic" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                              <i class="el-icon-loading"></i>
                            </div>
                          </el-image>
                        </el-popover>

                        <!-- 商品标题 -->
                        <el-popover placement="bottom" width="400" trigger="hover">
                          <div class="goodsTitleAll">
                            {{ goodsItem.goods_title }}
                          </div>

                          <div class="goodsTitle" slot="reference" style="cursor: pointer" @click="
                            $fun.toCommodityDetails({
                              goods_id: goodsItem.goods_id,
                              shop_type: goodsItem.shop_type,
                            })
                            ">
                            {{ goodsItem.goods_title }}
                          </div>
                        </el-popover>
                      </div>
                      <!-- 属性 -->
                      <div class="detail mainTd">
                        <el-popover placement="bottom" trigger="hover">
                          <div class="goodsDetailAll">
                            <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in goodsItem.detail"
                              :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                              <h1 class="detailTitle">{{ detailItem.key }}</h1>
                              <p>
                                <span class="detailBody">{{
                                  detailItem.value
                                }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="detailOptBox" slot="reference">
                            <p class="detailOpt" v-for="(detailItem, detailIndex) in goodsItem.detail"
                              :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                              {{ detailItem.key }}
                              :{{ detailItem.value }}

                            </p>
                          </div>
                        </el-popover>
                      </div>
                      <!-- 单价(￥) -->
                      <div class="price mainTd">
                        {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.confirm_price * orderItem.order.exchange_rate))
                        }}
                      </div>
                      <!-- 数量 -->
                      <div class="goodsNum mainTd">{{ goodsItem.confirm_num }}</div>
                      <!-- 总价(￥) -->
                      <div class="totalPrice mainTd">
                        {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.totalPrice * orderItem.order.exchange_rate)) }}
                      </div>
                      <!-- 国内运费 -->
                      <div class="homeFreight mainTd">
                        {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.confirm_freight * orderItem.order.exchange_rate))
                        }}
                      </div>

                      <!-- 在库数量 -->
                      <div class="deliverableQuantity mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1">
                        {{ goodsItem.totalWaitSubmitNum }}
                      </div>
                      <!-- 提出数 -->
                      <div class="putForwardNumber mainTd">
                        <el-input v-model="goodsItem.submitNum" :readonly="['临时保存'].indexOf(pageStatus) == -1"
                          oninput="this.value=Number(this.value.replace(/[^\d]/g,''))" @input="setPutNum(goodsItem)"
                          @change="setOrderNum"></el-input>
                      </div>
                      <!-- 备注 -->
                      <div class="remark mainTd">
                        <el-input class="remarkBox" :readonly="['临时保存'].indexOf(pageStatus) == -1"
                          v-model="goodsItem.client_remark" type="textarea" :placeholder="['临时保存'].indexOf(pageStatus) != -1
                            ? $fanyi('请输入备注')
                            : ''
                            ">
                        </el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$fanyi('装箱单')" name="deliveryorder">
            <!-- 装箱单信息-->
            <div class="Packinglist">
              <!-- <div class="headerdeliveryorderbox">
                <span v-for="(packingitem, ind) in deliverypackinglist"
                  :class="{ activepackingitem: packingitem.checked }" @click="packingitemclick(packingitem)"
                  :key="ind">{{
                    packingitem.porder_sn }}</span>
              </div> -->
              <!--费用地址信息  -->
              <div class="costbox">
                <div class="costboxrow">
                  <!-- 国际运输费用 -->
                  <div class="Internationaltransportationcosts">
                    <h2>{{ $fanyi('国际运输费用') }}</h2>
                    <div class="cost">
                      <!-- 国际物流成本 -->
                      <div class="costitem">
                        <span>{{ $fanyi('国际物流成本') }}:</span>
                        <span>${{ packinglistdetails.logistics_price }}</span>
                      </div>
                      <!-- 美国国内物流成本： -->
                      <div class="costitem">
                        <span>{{ $fanyi('美国国内物流成本') }}:</span>
                        <span>${{ packinglistdetails.foreign_express_price }}</span>
                      </div>
                      <!--其他费用  -->
                      <div class="costitem">
                        <span>{{ $fanyi('其他费用') }}:</span>
                        <span>${{ packinglistdetails.other_price }}</span>
                      </div>
                      <!--汇率  -->
                      <div class="costitem">
                        <span>{{ $fanyi('汇率') }}:</span>
                        <span>1CNY ≈ {{ packinglistdetails.exchange_rate }}</span>
                      </div>
                      <!-- 总成本 -->
                      <div class="costitem">
                        <span>{{ $fanyi('总成本') }}:</span>
                        <span>${{ packinglistdetails.total_price }}</span>
                      </div>
                    </div>

                    <!-- 装箱单状态 -->
                    <div class="Packingliststatusbox">
                      <div class="Packingliststatus">
                        <!-- 装箱单状态 -->
                        <span>{{ $fanyi('装箱单状态') }}</span>
                        <span class="status">{{ Packingliststatus(packinglistdetails.status) }}</span>
                      </div>
                      <div v-if="Number(packinglistdetails.status) == 30" @click="pay(porder_sn)" class="pay">{{
                        $fanyi('支付')
                      }}
                      </div>
                    </div>
                  </div>

                  <!-- 发货信息 -->
                  <div class="Shippinginformation">
                    <h2>{{ $fanyi('发货信息') }}</h2>
                    <div class="informationbox">
                      <div class="information">
                        <!-- Rakumart装箱单单号 -->
                        <span>{{ $fanyi('Rakumart装箱单单号') }} :</span>
                        <span> {{ packinglistdetails.porder_sn }}</span>
                      </div>
                      <!-- 收件人 -->
                      <div class="information">
                        <span> {{ $fanyi('收件人') }}:</span>
                        <span> {{ packinglistdetails.receive.contacts }}</span>
                      </div>
                      <!-- 地址 -->
                      <div class="information">
                        <span>{{ $fanyi('地址') }}: </span>
                        <span>{{ packinglistdetails.receive.address }}
                        </span>
                      </div>
                      <!-- 城市 -->
                      <div class="information">
                        <span>{{ $fanyi('城市') }}: </span>
                        <span>{{ packinglistdetails.receive.city }} </span>
                      </div>
                      <!-- 州 -->
                      <div class="information">
                        <span>{{ $fanyi('州') }}: </span>
                        <span> {{ packinglistdetails.receive.state }}</span>
                      </div>
                      <!-- 邮编 -->
                      <div class="information">
                        <span>{{ $fanyi('邮编') }}: </span>
                        <span> {{ packinglistdetails.receive.zip_code }}</span>
                      </div>
                      <!-- 电话 -->
                      <div class="information">
                        <span> {{ $fanyi('电话') }}:</span>
                        <span> {{ packinglistdetails.receive.mobile }} </span>
                      </div>
                      <!-- 邮箱 -->
                      <div class="information">
                        <span> {{ $fanyi('邮箱') }}:</span>
                        <span>{{ packinglistdetails.receive.email }} </span>
                      </div>
                    </div>
                  </div>

                  <!-- 装箱单备注 -->
                  <div class="Remarksofpackinglist">
                    <h2>{{ $fanyi('装箱单备注') }}</h2>
                    <textarea v-model="packinglistdetails.client_remark" :disabled="true"></textarea>
                    <h2>{{ $fanyi('包括以下订单中的产品') }}</h2>
                    <el-table height="132" :header-cell-style="{
                      'text-align': 'center',
                      'font-size': '14px ',
                      color: 'black',
                      'font-weight': '500',
                      background: '#F6F6F6',
                      height: '32px !important',
                      padding: '5px'
                    }" :cell-style="{
                      'text-align': 'center',
                      padding: '4px',
                      height: '32px !important', 'font-size': '14px',
                    }" :data="packinglistdetails.order_sn" border style="width: 100%">
                      <el-table-column prop="no" label="NO" width="139">
                      </el-table-column>
                      <el-table-column prop="order_sn" :label="$fanyi('订单号')" width="260">
                        <template slot-scope="scope">
                          <div @click="openorderDetails(scope.row.order_sn)" class="order_sn">
                            {{ scope.row.order_sn }}
                          </div>
                        </template>

                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>


              <!-- 物流信息 -->
              <div class="Logisticsinformation"> <!-- 配送单列表 -->
                <div class="Distributionlist">
                  <el-table :header-cell-style="{
                    'text-align': 'center',
                    'font-size': '14px ',
                    color: '#222222 ',
                    'font-weight': '500',
                    background: '#F6F6F6',
                    height: '40px !important',
                    padding: '5px'
                  }" :cell-style="{
                    'text-align': 'center',
                    padding: '4px',
                    height: '50px !important', 'font-size': '14px',
                  }" :data="LogisticsInfo" border style="width: 100%">
                    <!-- 运单号 -->
                    <el-table-column :label="$fanyi('运单号')">
                      <template slot-scope="scope">
                        <div @click="waybill(scope.row)" class="waybill">
                          {{ scope.row.express_no }}
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 物流方式 -->
                    <el-table-column :label="$fanyi('物流方式')">
                      <template slot-scope="scope">
                        <div>{{ scope.row ? scope.row.logistics_info.name : '' }}</div>
                      </template>
                    </el-table-column>
                    <!-- 装箱数量 -->
                    <el-table-column prop="box_number" :label="$fanyi('装箱数量')"> <template slot-scope="scope">
                        <div>{{ scope.row.box_number }}</div>
                      </template>
                    </el-table-column>
                    <!-- 重量 -->
                    <el-table-column prop="weight" :label="$fanyi('重量') + '(KG)'"><template slot-scope="scope">
                        <div>{{ scope.row.weight }}</div>
                      </template>
                    </el-table-column>
                    <!-- 体积 -->
                    <el-table-column prop="volume" :label="$fanyi('体积') + '(m³)'"><template slot-scope="scope">
                        <div>{{ scope.row.volume }}</div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <!-- 提示 -->
                  <div class="tip">
                    {{ $fanyi('提示') }} ：{{ $fanyi('您可以点击运单号查看实时物流信息') }}
                  </div>
                </div>

                <!-- 跟踪物流状态 -->

                <div class="TrackingStatus">
                  <h2>{{ $fanyi('跟踪状态') }}</h2>

                  <div class="iconbox">
                    <!-- Rakumart已经发货 -->
                    <div class="iconitem" :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 0) }">
                      <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 0)"
                        src="@/assets/newImg/Packinglist/1-1.svg" alt="">
                      <img v-else src="@/assets/newImg/Packinglist/1-2.svg" alt="">

                      <p>{{ $fanyi('Rakumart已经发货') }}</p>
                    </div>
                    <div class="line">

                    </div>
                    <!-- 中转 -->
                    <div class="iconitem" :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 1) }">
                      <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 1)"
                        src="@/assets/newImg/Packinglist/2-1.svg" alt="">
                      <img v-else src="@/assets/newImg/Packinglist/2-2.svg" alt="">
                      <p>{{ $fanyi('中转') }}</p>
                    </div>
                    <div class="line">

                    </div>
                    <!-- 美国国内派送 -->
                    <div class="iconitem" :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 2) }">
                      <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 2)"
                        src="@/assets/newImg/Packinglist/3-1.svg" alt="">
                      <img v-else src="@/assets/newImg/Packinglist/3-2.svg" alt="">
                      <p>{{ $fanyi('美国国内派送') }}</p>

                    </div>
                    <div class="line">

                    </div>
                    <!--交付 -->
                    <div class="iconitem" :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 3) }">
                      <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 3)"
                        src="@/assets/newImg/Packinglist/4-1.svg" alt="">
                      <img v-else src="@/assets/newImg/Packinglist/4-2.svg" alt="">
                      <p>{{ $fanyi('交付') }}</p>
                    </div>

                  </div>

                  <div class="textareabox" v-if="LogisticsInfo[0].information.length > 0">

                    <p v-for="(itm, i) in LogisticsInfo[0].information" :key="i"> <span>{{ itm.datetime
                        }}</span> <span>{{ itm.msg }}</span> </p>
                  </div>
                  <div class="noData" v-else>{{
                    $fanyi('暂无数据')
                  }}</div>

                </div>
              </div>
              <!-- 包装单详情 Packinglist details -->
              <div class="Packinglistdetails">
                <div class="detailscontent" :class="{ showCloseheight: showClose }" id="detailscontentRef">
                  <div class="headertitle">
                    <!--  @click="headerclick(ite)" -->
                    <div class="left" @click="headerclick">
                      <!-- 装箱单详细信息-->
                      <span>{{ $fanyi('装箱单详细信息') }}</span>
                      <!-- 提示 -->
                      <span class="tip">
                        {{ $fanyi('提示') + ': ' + $fanyi('点击此处展开或折叠所有部分') }}
                      </span>
                    </div>
                    <div class="right">
                      <!-- 展开 -->
                      <img v-if="showClose" @click="headerclick" src="@/assets/newImg/Packinglist/one-2.svg" alt="">
                      <!-- 收齐 -->
                      <img v-else @click="headerclick" src="@/assets/newImg/Packinglist/one-1.svg" alt="">

                    </div>

                  </div>
                  <div class="maintable" v-for="(ite, index) in Packinginformation" :key="index" id="maintableRef">
                    <el-collapse v-model="activeNames">
                      <el-collapse-item title="一致性 Consistency" name="1">
                        <template slot="title">
                          <div class="itemheader">

                            <!-- 箱号 -->
                            <div class="Cartonnumber">
                              <span> {{ $fanyi('箱号') }} :</span>
                              <span>{{ ite.number }}</span>
                            </div>
                            <!-- 规格 -->
                            <div class="specification">
                              <!-- 装有相同货物的箱子数量 -->

                              <div class="samegoods">
                                <span>{{ $fanyi('装有相同货物的箱子数量') }} :</span><span>{{ ite.total }}</span>
                              </div>
                              <!-- 重量 -->
                              <div class="Weight"> <span>{{ $fanyi('重量') }}(kg) :</span><span>{{ ite.weight }}</span>
                              </div>

                              <!-- 体积 -->
                              <div class="Volume"> <span>{{ $fanyi('体积') }} (m³):</span><span>{{ ite.volume }}</span>
                              </div>
                              <!-- 长 -->
                              <div class="Length"> <span>{{ $fanyi('长') }}(cm) :</span><span>{{ ite.length }}</span>
                              </div>
                              <!-- 宽 -->
                              <div class="Width"> <span>{{ $fanyi('宽') }}(cm) :</span><span>{{ ite.width }}</span>
                              </div>
                              <!-- 高 -->
                              <div class="Height"> <span>{{ $fanyi('高') }}(cm) :</span><span>{{ ite.height }}</span>
                              </div>

                            </div>
                          </div>

                        </template>
                        <!-- 表格区域 -->
                        <el-table :header-cell-style="{
                          'text-align': 'center',
                          'font-size': '14px ',
                          color: '#222222 ',
                          'font-weight': '500',
                          background: '#F6F6F6',
                          height: '40px !important',
                          padding: '5px'
                        }" :cell-style="{
                          'text-align': 'center',
                          padding: '4px',
                          height: '100px', 'font-size': '14px',
                        }" :data="ite.goods" border style="width: 100%">
                          <!-- 订单号 -->
                          <el-table-column width="220" :label="$fanyi('订单号')">
                            <template slot-scope="scope">
                              <div class="waybill">
                                {{ scope.row.order_sn }}
                              </div>
                            </template>
                          </el-table-column>
                          <!-- 序号 -->
                          <el-table-column type="index" width="80" :label="$fanyi('NO')">
                          </el-table-column>
                          <!-- 商品图片 -->
                          <el-table-column prop="pic" width="110" :label="$fanyi('商品图片')">
                            <template slot-scope="scope">
                              <el-popover placement="right" trigger="hover">
                                <img :src="scope.row.pic" alt="" style="width: 288px; height: 298px" />

                                <img class="goodimg" :src="scope.row.pic" alt="" slot="reference" />
                              </el-popover>
                            </template>

                          </el-table-column>
                          <!-- 商品链接 -->
                          <el-table-column prop="url" width="200" :label="$fanyi('商品链接')">
                            <template slot-scope="scope">

                              <el-popover placement="top-start" width="200" trigger="hover">
                                <div>{{ scope.row.url }}</div>
                                <div slot="reference" class="goods_title">
                                  {{ scope.row.url }}
                                </div>

                              </el-popover>

                            </template>
                          </el-table-column>
                          <!-- 产品英文名称 -->
                          <el-table-column prop="goods_title" width="160" :label="$fanyi('产品英文名称')">
                            <template slot-scope="scope">

                              <el-popover placement="top-start" width="200" trigger="hover">
                                <div>{{ scope.row.goods_title }}</div>
                                <div slot="reference" class="goods_title">
                                  {{ scope.row.goods_title }}
                                </div>

                              </el-popover>

                            </template>
                          </el-table-column>
                          <!-- 商品属性 -->
                          <el-table-column prop="name" width="200" :label="$fanyi('商品属性')
                            "> <template slot-scope="scope">
                              <p v-for="(column, ind) in scope.row.detail" :key="ind"><span>{{ column.key }}:</span>
                                <span>{{
                                  column.value }}</span> </p>
                            </template>
                          </el-table-column>
                          <!-- 包装数量 -->
                          <el-table-column prop="num" :label="$fanyi('包装数量')">
                          </el-table-column>
                          <!-- 单价 -->
                          <el-table-column prop="price_usd" :label="$fanyi('单价') + '($)'">
                          </el-table-column>
                          <!-- 小计 -->
                          <el-table-column prop="total_price_usd" :label="$fanyi('小计') + '($)'">
                          </el-table-column>
                        </el-table>
                      </el-collapse-item>

                    </el-collapse>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

      </div>
      <br />
      <caozuolanVue ref="caozuolan" @showSelectCouponDialogVisible="showSelectCouponDialogVisible"></caozuolanVue>
    </div>

    <!-- 通关方式 -->
    <tongGuanFangShiVue v-if="activeName == 'porder_sn'"></tongGuanFangShiVue>

    <el-dialog :visible.sync="couponChecked" width="480px" :show-close="false" @close="closeDlong">
      <coupons ref="Deliveryorder" @cloneDialogVisible="cloneDialogVisible" :status="datas.status"
        porder_sn=" porder_sn" @getActiveCoupon="getActiveCoupon" :key="timer" />
    </el-dialog>
    <dgoodsInfo v-if="['临时保存', '报价中'].indexOf(pageStatus) == -1 && activeName == 'porder_sn'" />
    <customerPrompt ref="customerPrompt" style="background: transparent; box-shadow: none; height: 0px">
    </customerPrompt>
    <obligationOrderAddress v-if="activeName == 'porder_sn'" />
  </div>
</template>
<script>
import HomePageTop from '../../../../../../components/head/HomePageTop.vue'

import dgoodsInfo from './components/dgoodsInfo.vue'
import Dialog from '../../../../../../components/public/Dialog.vue'

import customerPrompt from './components/customerPrompt'
import tongGuanFangShiVue from './components/tongGuanFangShi.vue'

import caozuolanVue from './components/caozuolan.vue'

import obligationOrderAddress from './components/obligationOrderAddress'
import coupons from '../../../userOrder/views/components/deliveryorderCoupons.vue'
import Europess from '@/api/wangZhi'
let Europe = Europess + '/client/'
import axios from 'axios';
export default {
  name: 'DeliveryOrderDetails',
  data() {
    return {
      datas: { result: {} }, //所有数据
      tableData: [], //上面表格数据
      orderData: null, //配送单详情页面下面表格数据
      checked: false, //全选
      pageStatus: '待发货', //页面状态，参数：临时保存、报价中、等待付款、待发货
      timer: '',
      couponChecked: false,
      // 接口请求条件
      form: {
        ids: this.$route.query.id,
        personal_clearance: 'yes',
        logistics: '',
        client_remark: '',
      },
      activeName: 'deliveryorder',
      activeNames: ['1'],

      deliverypackinglist: [],//配送单单号列表
      packinglistdetails: {
        receive: {}
      },
      LogisticsInfo: [{
        information: '', logistics_info: {
          name: ''
        }
      }],//物流信息
      showClose: true,
      Packinginformation: [],// 装箱信息
      wuliu: [],
      // 订单其他信息
      orderData: {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      },
      // 配送单号
      porder_sn: '',
      wuLiuOptions: [],
      //选择的地址名字
      userActiveAddName: {
        importer: '',
        consignee: '',
      },
      //选择的地址详细信息
      userActiveAdd: {
        importer: {
          user_id: 1,
          type: 1,
          company: '',
          zip_code: '',
          address: '',
          contacts: '',
          mobile: '',
          tel: '',
          mark: '',
          email: '',
          cnpj: '',
        },
        consignee: {
          user_id: 1,
          type: 2,
          company: '',
          zip_code: '',
          address: '',
          contacts: '',
          mobile: '',
          tel: '',
          mark: '',
          email: '',
          cnpj: '',
        },
      },



      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('删除'), this.$fanyi('取消')],
      },
    }
  },
  components: {
    HomePageTop,
    dgoodsInfo,


    Dialog,
    customerPrompt,
    tongGuanFangShiVue,

    caozuolanVue,

    obligationOrderAddress,
    coupons,
  },
  computed: {},
  created() {
    // 获取数据
    this.getData()
    // 获取用户地址
    this.useraddressList()
    // // 获取物流方式列表
    this.packingList(this.$route.query.order_sn)
    this.getLogisticsInfo(this.$route.query.order_sn)
    this.getPackingInfo(this.$route.query.order_sn)

  },
  methods: {

    // 获取数据
    getData() {
      if (this.$route.query.order_sn) {
        this.$api
          .storageDeliverDetail({ porder_sn: this.$route.query.order_sn })
          .then((res) => {
            if (res.code != 0) return

            this.porder_sn = res.data.porder_sn

            this.datas = res.data

            this.wuliu = res.data.result.porder_foreign_express


            this.pageStatus = res.data.result.status_name
            console.log(this.pageStatus);

            // 备注
            this.form.client_remark = res.data.result.client_remark
            // 地址
            if (res.data.result.importer) {
              this.userActiveAdd.importer = res.data.result.importer
            }
            if (res.data.result.receive) {
              this.userActiveAdd.consignee = res.data.result.receive
            }

            // 通关方式
            if (res.data.result.personal_clearance_name == 'rakumart代通关') {
              this.form.personal_clearance = 'yes'
            } else if (res.data.result.personal_clearance_name == '个人通关') {
              this.form.personal_clearance = 'no'
            }

            this.tableData = this.datas.result.porder_detail

            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia()
            this.detailDataTreating()
            this.$api.internationalLogisticsTransportation().then((res) => {
              if (res.code != 0) return
              if (res.data.length == 0) return false
              console.log(res.data, this.datas.result.logistics);

              res.data.forEach((item) => {
                if (
                  item.useful_rule.logistics_id === this.datas.result.logistics
                ) {
                  this.form.logistics = item.name
                }
              })

            })
          })
      } else if (this.$route.query.id) {
        this.pageStatus = '临时保存'
        this.$api
          .storageSendDeliverPreview({ ids: this.form.ids })
          .then((res) => {
            if (res.code != 0) return
            this.datas = res.data

            this.porder_sn = res.data.porder_sn
            this.tableData = res.data.result
            // 进口商地址默认赋值
            this.userActiveAdd.importer = this.datas.importer_address
            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia()
            this.dataTreating()
            this.$api.internationalLogisticsTransportation().then((res) => {
              if (res.code != 0) return
              if (res.data.length == 0) return false
              res.data.forEach((item) => {
                if (
                  item.useful_rule.logistics_id === this.datas.result.logistics
                ) {
                  this.form.logistics = item.name
                }
              })
              this.wuLiuOptions = res.data
            })
          })
      }
    },
    // 计算商品种类和数量总计
    setOrderNum() {
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
        })
      })

    },
    //关闭弹窗
    cloneDialogVisible(msg) {

      this.couponChecked = msg
      this.$refs.caozuolan.couponChecked = msg
    },
    // 关闭时触发的时间
    closeDlong() {
      if (!this.coupon_ids) {
        this.$refs.caozuolan.couponChecked = false
      }

    },
    //将选择的优惠券后的价格重新计算
    async getActiveCoupon(msg, flag) {
      this.coupon_ids = msg

      const res = await this.$api.storagePOrderPayment({
        porder_sn: this.porder_sn,
        coupon_ids: msg,
      })
      if (res.code == 10000) {
        this.$message.error(this.$fanyi(res.msg))
        return
      }

      this.couponChecked = false
      this.$refs.caozuolan.couponChecked = flag
      //  Vue.set(this.datas.result,'amount',res.data.porder_amount)
      this.datas.result.amount = res.data.porder_amount
    },
    // 配送单详情处理数据
    detailDataTreating() {

      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true
        orderItem.checked = false

        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
          goodsItem.totalPrice = this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          )
          goodsItem.checked = false
        })
      })

      //费用总计= 其他费用+国际物流费用+美国物流费用+增值税+反倾销税，因为购买商品的费用在之前已经支付过了
      this.orderData.zongJia =
        Number(this.datas.result.other_price) +
        Number(this.datas.result.logistics_price) +
        Number(this.datas.result.foreign_express_price) +
        Number(this.datas.result.added_price) +
        Number(this.datas.result.clearance_price)
    },
    // 配送单提出预览处理数据
    dataTreating() {
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true
        orderItem.checked = false
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
          this.orderData.zongJia += this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          )
          goodsItem.totalPrice = Number(
            this.$fun.ceil(goodsItem.confirm_num * goodsItem.confirm_price)
          )
          goodsItem.checked = false
        })
      })
    },


    headerclick(row) {
      this.showClose = !this.showClose
      this.$forceUpdate()
    },
    // 配送单的packinglist
    async packingList(porder_sn) {


      const res = await this.$api.packinglist({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.packinglistdetails = res.data


    },

    Packingliststatus(status) {
      //       已删除 ：-1
      // 临时保存 ：10
      // 正式提交 ：20
      // 业务已翻译待装箱 ：21
      // 已装箱完成待报价 ：22
      // 已报价 ：30 待支付 ：30
      // 已支付待确认 ：40
      // 已支付已确认 ：50
      // 已订舱待回复 ：51
      // 待装柜 ：52
      // 待出货 ：53
      // 已发出 ：60
      // 已通关 ：62
      // 西班牙国内开始派送 ：64
      // 客户已签收 ： 70
      // 异常 ： -2
      let textStr = ''
      if (Number(status) == -1) {
        textStr = this.$fanyi('已删除')
      }
      if (Number(status) == 10) {
        textStr = this.$fanyi('临时保存')
      }
      if (Number(status) == 20) {
        textStr = this.$fanyi('正式提交')
      }
      if (Number(status) == 21) {
        textStr = this.$fanyi('业务已翻译待装箱')
      }
      if (Number(status) == 22) {
        textStr = this.$fanyi('已装箱完成待报价')
      }
      if (Number(status) == 30) {
        textStr = this.$fanyi('待支付')
      }
      if (Number(status) == 40) {
        textStr = this.$fanyi('已支付待确认')
      }
      if (Number(status) == 50) {
        textStr = this.$fanyi('已支付已确认')
      }
      if (Number(status) == 51) {
        textStr = this.$fanyi('已订舱待回复')
      }
      if (Number(status) == 52) {
        textStr = this.$fanyi('待装柜')
      }
      if (Number(status) == 53) {
        textStr = this.$fanyi('待出货')
      }
      if (Number(status) == 60) {
        textStr = this.$fanyi('已发出')
      }
      if (Number(status) == 62) {
        textStr = this.$fanyi('已通关')
      }
      if (Number(status) == 64) {
        textStr = this.$fanyi('美国国内配送中')
      }
      if (Number(status) == 70) {
        textStr = this.$fanyi('已签收')
      }
      if (Number(status) == -2) {
        textStr = this.$fanyi('异常')
      }


      return textStr
    },
    async getLogisticsInfo(porder_sn) {
      const res = await this.$api.getLogisticsInfo({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.LogisticsInfo = res.data
    },

    async getPackingInfo(porder_sn) {
      const res = await this.$api.getPackingInfo({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.Packinginformation = res.data


    },

    getStatusArray(status, index) {
      let ind = ['rakumart', 'in transit', 'US domestic dilivery', 'delivered'].findIndex((i) => i == status)


      if (ind !== -1) {
        if (index <= ind) {
          return true
        }
        else {
          return false
        }
      } else {
        return false
      }
    },

    packingitemclick(packingitem) {
      this.deliverypackinglist.forEach((item) => {
        item.checked = false
      })
      packingitem.checked = true
      this.porder_sn = packingitem.porder_sn
      this.packingList(packingitem.porder_sn)
      this.getLogisticsInfo(packingitem.porder_sn)
      this.getPackingInfo(packingitem.porder_sn)
      this.$forceUpdate()
    },

    Packingliststatus(status) {
      //       已删除 ：-1
      // 临时保存 ：10
      // 正式提交 ：20
      // 业务已翻译待装箱 ：21
      // 已装箱完成待报价 ：22
      // 已报价 ：30 待支付 ：30
      // 已支付待确认 ：40
      // 已支付已确认 ：50
      // 已订舱待回复 ：51
      // 待装柜 ：52
      // 待出货 ：53
      // 已发出 ：60
      // 已通关 ：62
      // 西班牙国内开始派送 ：64
      // 客户已签收 ： 70
      // 异常 ： -2
      let textStr = ''
      if (Number(status) == -1) {
        textStr = this.$fanyi('已删除')
      }
      if (Number(status) == 10) {
        textStr = this.$fanyi('临时保存')
      }
      if (Number(status) == 20) {
        textStr = this.$fanyi('正式提交')
      }
      if (Number(status) == 21) {
        textStr = this.$fanyi('业务已翻译待装箱')
      }
      if (Number(status) == 22) {
        textStr = this.$fanyi('已装箱完成待报价')
      }
      if (Number(status) == 30) {
        textStr = this.$fanyi('待支付')
      }
      if (Number(status) == 40) {
        textStr = this.$fanyi('已支付待确认')
      }
      if (Number(status) == 50) {
        textStr = this.$fanyi('已支付已确认')
      }
      if (Number(status) == 51) {
        textStr = this.$fanyi('已订舱待回复')
      }
      if (Number(status) == 52) {
        textStr = this.$fanyi('待装柜')
      }
      if (Number(status) == 53) {
        textStr = this.$fanyi('待出货')
      }
      if (Number(status) == 60) {
        textStr = this.$fanyi('已发出')
      }
      if (Number(status) == 62) {
        textStr = this.$fanyi('已通关')
      }
      if (Number(status) == 64) {
        textStr = this.$fanyi('美国国内配送中')
      }
      if (Number(status) == 70) {
        textStr = this.$fanyi('已签收')
      }
      if (Number(status) == -2) {
        textStr = this.$fanyi('异常')
      }


      return textStr
    },
    // 下载表格数据 
    async Downloadpackinglist() {
      axios.post(Europe + 'download.exportPackingList', {
        porder_sn: this.porder_sn,
      }, {
        responseType: "blob", // 1.首先设置responseType对象格式为 blob:
      }).then((res) => {
        console.log(res);

        if (res.size < 200) {
          return this.$message.warning(this.$fanyi("操作失败"));
        }
        let blob = new Blob([res],
          { type: "text/html" });

        // "application/vnd.ms-excel"

        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = this.porder_sn + ".xls";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);


      }).catch((error) => {
        this.$message(error.message);
        return
      });

    },

    // 点击table切换
    handleClick() { },

    //展示选择优惠券弹窗
    showSelectCouponDialogVisible(e) {

      if (e == true) {
        this.couponChecked = true
      } else {

        this.getActiveCoupon('', e)
      }
    },
    // 临时保存或提交订单并跳转到配送单列表页面
    storageSendDeliver(type) {
      if (type == 'formal') {
        // 收货地址
        for (let i in this.userActiveAdd.consignee) {

          if (
            [
              'type',
              'company',
              'zip_code',
              'address',
              'contacts',
              'mobile',
              'tel',
              'email',
              'cnpj',
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.consignee[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee)
            return this.$message(this.$fanyi('提出配送单请将地址填写完整'))
          }
        }
        let url = this.$route

        // 进口商
        for (let i in this.userActiveAdd.importer) {
          if (
            [
              'type',
              'company',
              'zip_code',
              'address',
              'contacts',
              'mobile',
              'tel',
              'email',
              'cnpj',
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.importer[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee)
            return this.$message(this.$fanyi('提出配送单请将地址填写完整'))
          }
        }
      }
      let ids = []
      this.tableData.forEach((orderItem) => {
        delete orderItem.showGoodsList
        delete orderItem.checked

        orderItem.order_detail.forEach((goodsItem) => {
          delete goodsItem.totalPrice
          delete goodsItem.checked
          ids.push(goodsItem.id)
        })
      })
      if (ids.length == 0) {
        return this.$message(this.$fanyi('订单里请至少添加一款商品'))
      }
      let datas = {
        submitStatus: type,
        porder_sn: this.porder_sn,
        ids: ids.join(','),
        data: JSON.stringify(this.tableData),
        logistics: this.form.logistics,
        personal_clearance:
          this.form.personal_clearance == 'yes'
            ? 'no'
            : this.form.personal_clearance == 'no'
              ? 'yes'
              : this.form.personal_clearance,
        client_remark: this.form.client_remark,
      }
      // 假如是个人通关则传递地址
      // if (this.form.personal_clearance == "no") {
      datas.consignee_address = JSON.stringify(this.userActiveAdd.consignee)
      datas.importer_address = JSON.stringify(this.userActiveAdd.importer)
      // }

      if (
        this.form.personal_clearance == 'no' &&
        datas.consignee_address == {} &&
        datas.importer_address == {}
      ) {
        return this.$message(this.$fanyi('通关方式为个人通关，请填写地址信息'))
      }
      this.$api.storageSendDeliver(datas).then((res) => {

        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        // 如果是临时保存，跳转到配送单列表临时保存的状态
        if (type != 'formal') {
          this.$fun.toPage('/user/deliveryList?status=temporary')
        } else {
          this.$fun.toPage('/user/deliveryList')
        }
      })
    },
    // 将指定id的对象填充到地址框
    findValue(e, type) {

      let items = this.useraddLists[type].find((item) => item.id == e)
      this.userActiveAdd[type] = items

    },
    // 获取用户所有地址
    useraddressList() {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return false
        this.useraddLists = res.data

        // 检查用户是否有修改过收货地址
        let notNull = false
        for (let consiitem in this.userActiveAdd.consignee) {
          if (
            ['id', 'mark', 'type', 'user_Id'].indexOf(
              this.userActiveAdd.consignee[consiitem]
            ) == -1 &&
            this.userActiveAdd.consignee[consiitem]
          ) {
            notNull = true
          }
        }

        // 如果收货列表存在并且配送单状态是临时保存并且用户未填写地址时匹配默认地址,另外一个页面打开的时候不需要匹配，因为默认是代通关，如果个人通关用户一定填了地址
        if (
          this.useraddLists.consignee &&
          ['临时保存'].indexOf(this.pageStatus) != -1 &&
          notNull == true
        ) {
          this.useraddLists.consignee.forEach((addListItem) => {
            if (addListItem.acquiescent) {
              this.userActiveAddName.consignee = addListItem
              this.findValue(addListItem.id, 'consignee')
            }
          })
        }
      })
    },
    waybill(row) {

      if (row.logistics_info.url) {
        if (Number(row.logistics_info.is_inquire) == 1) {
          window.open(row.logistics_info.url + row.express_no)
        } else {
          window.open(row.logistics_info.url)
        }

      }



    },
    // 限制提出数量不能大于可提出数
    setPutNum(item) {
      if (item.totalWaitSubmitNum < item.submitNum) {
        item.submitNum = item.totalWaitSubmitNum
        this.$message(this.$fanyi('提出数不能超过可提出数'))
      }
    },

    // 前往付款页面
    goPay() {
      this.$fun.toPage(
        '/payment?porder_sn=' +
        this.porder_sn +
        '&peiSong=true' +
        '&coupon_ids=' +
        this.coupon_ids
      )
    },

  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin.scss';

.DeliveryDetails {
  padding-bottom: 156px;
  background: #f9f9f9;
}

/deep/ .el-dialog {
  border-radius: 6px;
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  // overflow: auto;
  padding: 0;
}

/deep/ .el-dialog__footer {
  padding: 0 30px 30px;
}

.showdow {
  width: $pageWidth;
  margin: 0 auto;
}

#DeliveryDetailsTable {
  width: $pageWidth;
  background: #ffffff;
  border-bottom: none;

  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  margin: 10px auto 0;
  padding: 20px 20px 40px 20px;

  .DeliveryDetailsTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    line-height: 28px;

    letter-spacing: 1px;

    .left {
      font-family: Roboto, Roboto;
      font-weight: 500;
      flex: 0 0 500px;
      font-size: 18px;
      color: #222222;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }

    .right {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .tishi {
        font-weight: 400;
        font-size: 14px;
        color: #E3372F;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }

      .Downloadpackinglist {
        margin-left: 20px;

        min-height: 32px;
        padding: 0px 10px;
        border-radius: 4px;
        border: 1px solid #FF730B;
        font-weight: 400;
        font-size: 14px;
        color: #FF730B;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  h1 {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #222222;
    letter-spacing: 1px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    .tishi {
      min-width: 300px;
      height: 32px;
      background: #eff4ff;
      border-radius: 4px;
      padding: 0 10px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #608bff;
      line-height: 32px;
    }
  }

  .main {
    width: 100%;

    // border: 1px solid #e2e2e2;
    .head {
      width: 100%;
      height: 60px;
      background: #f6f6f6;
      border: 1px solid #e2e2e2;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      position: sticky;
      z-index: 9;

      border: 1px solid #e2e2e2;
      top: 67px;

      .mainTd {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    .body {
      .bodyOpt {
        // margin-bottom: 20px;

        .optTitle {
          display: flex;
          align-items: center;
          height: 60px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          border-top: 0;

          .orderSn {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            padding-left: 10px;

            button {
              font-size: 14px;
              color: $homePageSubjectColor;
            }
          }

          img {
            margin-left: 12px;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            // transform: rotate(90deg);
            transition: 0.3s;
            cursor: pointer;

            &.isShow {
              transform: rotate(360deg);
            }
          }
        }

        .optBody {
          border-top: none;

          .bodyRow {
            height: 120px;
            display: flex;
            align-items: center;
            background: #f6f6f6;
            border: 1px solid #e2e2e2;
            font-size: 14px;
            border-top: 0;

            .goods {
              flex: 0 0 320px;

              // width: 320px;
              /deep/.el-image {
                $imgSize: 60px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;
                margin-right: 10px;
                cursor: pointer;

                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }

              .goodsTitle {
                width: 100%;
                height: 80px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
              }
            }

            .detail {
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-overflow: -o-ellipsis-lastline;
              padding: 10px 0;
              overflow: hidden;
            }

            .putForwardNumber {
              padding: 0px;

              /deep/.el-input__inner {
                height: 32px;
                text-align: center;
              }
            }

            .remark {
              display: flex;
              justify-content: center;
              align-items: center;

              .remarkBox {
                width: 140px;

                background: #ffffff;
                border-radius: 2px;
                height: 80px;

                /deep/.el-textarea__inner {
                  height: 80px;
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }

    .mainTd {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.check-Box {
        flex: 0 0 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.choice {
        flex: 0 0 70px;
      }

      &.goods {
        flex: 0 0 254px;
        justify-content: flex-start;
        padding: 0 10px;
      }

      &.detail {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 140px;
        height: 100%;

        p {
          width: 120px;
        }
      }



      &.homeFreight {
        flex: 0 0 100px;
      }

      &.tariff {
        flex: 0 0 100px;
      }


      &.putForwardNumber {
        flex: 0 0 120px;
      }

      &.remark {
        flex: 0 0 160px;
        border-right: none;
      }
    }
  }

  /deep/.el-tabs {

    .el-tabs__content {
      overflow: visible;

    }

    .el-tabs__header {
      height: 36px;
      margin-bottom: 20px;

      .el-tabs__nav-wrap {
        height: 36px;
      }
    }

    .el-tabs__nav-scroll {
      height: 36px;
    }

    .el-tabs__nav {
      height: 36px;
    }

    .el-tabs__item {
      height: 36px;
      font-size: 16px;
      color: #222222;
      line-height: 24px;
      text-align: center;
      line-height: normal;
    }

    .el-tabs__nav-wrap {
      height: 36px;
    }

    .is-active {
      font-weight: 500;
      font-size: 16px;
      color: #ff730b;
    }
  }

  //装箱单样式
  .Packinglist {
    width: 100%;
    padding-bottom: 10px;

    .headerdeliveryorderbox {
      width: 100%;
      min-height: 40px;
      background: #f6f6f6;
      border-radius: 6px;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      span {
        width: 210px;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        color: #222222;
        align-items: center;
        justify-content: center;
        height: 32px;

        &:hover {
          cursor: pointer;
        }
      }

      .activepackingitem {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
      }
    }

    .costbox {
      .costboxrow {
        width: 100%;
        min-height: 370px;
        background: #f6f6f6;
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .Internationaltransportationcosts {
          width: 440px;
          background: #ffffff;
          border-radius: 6px;
          min-height: 350px;
          padding: 20px;

          h2 {
            height: 30px;

            font-weight: 500;
            font-size: 16px;
            color: #222222;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            margin-bottom: 10px;
          }

          .cost {
            .costitem {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              line-height: 20px;

              span {
                width: 200px;

                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666 !important;
                  text-align: left;
                }

                &:last-child {
                  text-align: right;
                  font-weight: 500;
                  font-size: 14px;
                  color: #222222;
                }
              }
            }
          }

          .Packingliststatusbox {
            margin-top: 25px;
            min-height: 102px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: end;

            .Packingliststatus {
              display: flex;
              width: 400px;
              justify-content: space-between;

              span {
                font-weight: 500;
                font-size: 16px;
                color: #222222;
              }

              .status {
                height: 32px;
                background: #fff8f3;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                font-weight: 500;
                font-size: 14px;
                color: #ff730b;
              }
            }

            .pay {
              margin-top: 20px;
              width: 400px;
              height: 50px;
              background: #ff730b;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              color: #ffffff;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .Shippinginformation {
          width: 440px;
          min-height: 350px;
          background: #ffffff;
          border-radius: 6px;
          padding: 20px;

          h2 {
            height: 30px;

            font-weight: 500;
            font-size: 16px;
            color: #222222;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            margin-bottom: 10px;
          }

          .informationbox {
            .information {
              display: flex;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              line-height: 20px;

              span {
                width: 200px;
                // 放不下换行
                word-break: break-all;

                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666 !important;
                  text-align: left;
                }

                &:last-child {
                  text-align: right;
                  font-weight: 500;
                  font-size: 14px;
                  color: #222222;
                }
              }
            }
          }
        }

        .Remarksofpackinglist {
          width: 440px;
          min-height: 350px;
          background: #ffffff;
          padding: 20px;
          border-radius: 6px;

          h2 {
            height: 30px;

            font-weight: 500;
            font-size: 16px;
            color: #222222;
            line-height: 30px;
            text-align: left;
            margin-bottom: 10px;
            font-style: normal;
          }

          textarea {
            width: 400px;
            height: 80px;
            padding: 10px;
            background: #f9f9f9;
            border-radius: 4px;
            border: 1px solid #e2e2e2;
            margin-bottom: 20px;
          }

          .order_sn {
            font-weight: 400;
            font-size: 14px;
            color: #FF730B;
            text-decoration: underline;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    /deep/.el-table {
      border-radius: 6px;
      overflow: hidden;
    }


    .Logisticsinformation {
      min-height: 200px;
      background: #F6F6F6;
      border-radius: 10px;
      padding: 10px;

      // 配送单列表
      .Distributionlist {
        padding: 20px;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 10px;

        .waybill {
          font-weight: 400;
          font-size: 14px;
          color: #FF730B;
          line-height: 20px;
          text-align: center;
          font-style: normal;
          text-decoration-line: underline;

          &:hover {
            cursor: pointer;
          }
        }

        .tip {
          font-weight: 400;
          font-size: 14px;
          color: #E3372F;
          line-height: 20px;
          text-align: left;
          margin-top: 10px;
        }
      }

      .TrackingStatus {
        min-height: 104px;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 20px;

        h2 {
          font-weight: 500;
          font-size: 16px;
          color: #222222;
          line-height: 30px;
          text-align: left;
        }

        .iconbox {
          padding: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          .line {
            width: 160px;
            height: 2px;
            background: #F8F8F8;
          }

          .iconitem {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 80px;
              height: 80px;
            }

            p {
              margin-top: 10px;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
            }
          }

          .activeicon {
            p {
              font-weight: 400;
              font-size: 14px;

              line-height: 20px;
              text-align: center;
              color: #222222 !important;
            }
          }

        }

        .textareabox {
          min-height: 80px;
          background: #FFFFFF;
          border-radius: 6px;
          padding: 20px;
          border: 1px solid #E2E2E2;

          p {
            display: flex;
            align-items: center;

            span {
              &:first-child {
                display: flex;
                width: 130px;
                margin-right: 10px;
                font-weight: 400;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
              }

              &:last-child {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #222222;
              }
            }
          }
        }

        .noData {
          height: 80px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 1px solid #E2E2E2;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .Packinglistdetails {

      background: #F6F6F6;
      border-radius: 10px;
      margin-top: 20px;
      padding: 10px;

      .detailscontent {
        height: 70px;
        background: #FFFFFF;
        border-radius: 6px;
        overflow: hidden;
        padding: 20px;

        .headertitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          &:hover {
            cursor: pointer;
          }

          .left {
            display: flex;
            align-items: center;

            span {
              height: 30px;

              font-weight: 500;
              font-size: 16px;
              color: #222222;
              line-height: 30px;
              text-align: left;
              display: flex;
              align-items: center;
            }

            .tip {
              height: 20px;
              margin-left: 20px;

              font-weight: 400;
              font-size: 14px;
              color: #E3372F;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
          }
        }

        .goods_title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          font-size: 12px;
          overflow: hidden;
          text-align: left;

        }

        .maintable {
          /deep/.el-collapse {
            border-top: 1px solid #D8D8D8;

            .el-collapse-item__header {
              border-bottom: 1px solid none;
              height: 90px;

              .itemheader {
                height: 90px;
                padding-top: 20px;

                .Cartonnumber {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  height: 20px;

                  span {

                    &:first-child {
                      font-weight: 400;
                      font-size: 14px;
                      color: #222222;
                      line-height: 20px;
                      display: flex;
                      margin-right: 10px;
                    }

                    &:last-child {
                      font-weight: 500;
                      font-size: 14px;
                      color: #00C18B;
                      line-height: 20px;
                      text-align: left;
                    }
                  }
                }

                .specification {

                  display: flex;
                  width: 100%;
                  align-items: center;
                  min-height: 20px;

                  .samegoods,
                  .Weight,
                  .Volume,
                  .Length,
                  .Width,
                  .Height {

                    display: flex;
                    margin-right: 40px;

                    span {
                      display: flex;
                      align-items: center;

                      &:first-child {
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666;
                        line-height: 20px;
                        text-align: center;
                        margin-right: 8px;
                      }

                      &:last-child {
                        font-weight: 500;
                        font-size: 14px;
                        color: #222222;
                        line-height: 20px;
                      }
                    }
                  }


                }
              }

            }

            .el-collapse-item__arrow {
              width: 40px;
              height: 30px;
              background: #FFFFFF;
              border-radius: 6px;
              border: 1px solid #E2E2E2;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;


              &::before {

                transition: transform .3s;
              }

              &.is-active {

                // 取消它的旋转transform
                transform: none;

                &::before {
                  transform: rotate(90deg);
                  font-weight: 700;
                  color: #222222;
                  font-size: 14px;
                  transition: transform .3s;
                }
              }
            }

            .el-collapse-item__wrap {
              border-bottom: 1px solid #D8D8D8;
            }

            .el-collapse-item__content {
              padding-bottom: 20px;
            }
          }
        }
      }

      .goodimg {
        width: 80px;
        height: 80px;
        background: #ededed;
        border-radius: 6px;
      }

      .showCloseheight {
        height: auto !important;
        ;
      }
    }
  }

}

.theFullCost {
  display: flex;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  margin: 0 auto 0px;
  border-radius: 0px;

  .fujiafuwu {
    position: relative;

    .priceDetail {
      position: absolute;
      top: 10px;
      left: 100%;
      margin-left: 10px;
      width: 242px;
      min-height: 74px;
      background-color: white;
      z-index: 2;

      >ul {
        display: flex;
        border-radius: 10px;

        >li {
          height: 37px;
          background: #fafafa;
          flex: 1;
          height: 37px;
          line-height: 37px;
          border: solid 1px #ededed;
          margin: 0 -1px -1px 0;
          font-size: 12px;
          color: #000000;
        }

        &.obody {
          li {
            background-color: white;

            &:first-child {
              background: #fafafa;
            }
          }
        }
      }
    }
  }

  >div {
    padding: 20px;

    >h2 {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &.Con {
      width: 270px;
      font-size: 12px;

      color: #000000;
      line-height: 16px;

      /deep/.tgfs {
        margin: 3px 0 0;
        width: 210px;
      }


    }

    &.yunShuGongSi {
      margin-right: 30px;

      .ysm {
        margin-bottom: 10px;
      }

      >span {
        display: block;
        width: 180px;
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }

    &.teShuBeiZhu {
      flex: 1;

      >h2 {
        margin-top: 10px;
        line-height: unset;
      }

      >textarea {
        width: 100%;
        height: 120px;
        border-radius: 4px;
        border: 1px solid #e4e7ed;
        margin-bottom: 5px;
        padding-left: 10px;
        padding-top: 7px;
        background-color: white;
      }

      >span {
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }
  }
}

.addressAdministration {
  margin: 0 auto;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  margin-bottom: 20px;
  display: flex;

  padding: 30px;

  .optOne {
    &:first-child {
      margin-right: 236px;
    }

    .modelTitle {
      font-weight: 600;
      white-space: nowrap;
    }

    >p {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.oheader {
        margin-bottom: 20px;
        min-height: 30px;

        span {
          display: flex;
          justify-content: flex-start !important;
          margin-right: auto;
        }

        .oselect {
          /deep/.el-input {
            width: 180px;
            height: 30px;
            // border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-input__inner {
            font-size: 12px;

            color: #000000;
            line-height: 16px;
            width: 120px;
            height: 34px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid rgba(96, 139, 255, 0.3);

            height: 34px;
          }

          /deep/.el-select__caret {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      >span {
        display: flex;
        align-items: center;

        &:first-child {
          width: 145px;
          justify-content: flex-end;
          margin-right: 10px;
          font-size: 12px;
        }
      }

      >input,
      .dizhiShurukuang {
        width: 240px;
        height: 22px;
        background: #fff;
        border-radius: 3px;
        border: 1px solid #d7d7d7;
        padding-left: 10px;

        &::placeholder {
          color: #ccc;
        }
      }

      >.dizhiShurukuang {
        height: 80px;
      }
    }
  }
}

.feiYongDizhi {
  width: 1340px;
  margin: 0 auto;
  height: 1px;
  border: none;
  background-color: #dbdbdb;
  margin-bottom: -1px;
}

.detailOptBox {
  width: 120px;
  height: 80px;


  overflow-y: auto !important;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c0c0c0;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .detailOpt {
    max-width: 116px;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow-y: auto;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    span {
      display: inline-block;
      width: 120px;
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.goodsTitleAll {
  font-size: 18px;
}

/deep/.is-disabled .el-input__inner {
  color: black !important;
}

/deep/.selectTextBox {
  pointer-events: none;

  span {
    display: none;
  }
}
</style>
